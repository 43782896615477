import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';

require('../src/assets/images/menu.svg');

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;

const initContent = ($content) => {
    console.log('start app');

    loadLazyImages();
};

domready(function () {
    const initApplication = () => {
        initContent(document);

        window.eventScroller.init();
        window.resizeHandler.init();

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });
        console.log('init');
        window.dispatchEvent(contentUpdateEvent);
    };

    initApplication();
});
